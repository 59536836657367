import gql from "graphql-tag";

import { shiftBaseFragment } from "@/graphql/shifts/fragments/shiftBase";
import { employeeBaseFragment } from "@/graphql/employees/fragments/employeeBase";
import { workAreaBaseFragment } from "@/graphql/workAreas/fragments/workAreaBase";

export const shiftFullFragment = {
  shiftFull: gql`
    fragment ShiftFull on shift {
      ...ShiftBase
      employee {
        ...EmployeeBase
      }
      work_area {
        ...WorkAreaBase
      }
    }
    ${shiftBaseFragment.shiftBase}
    ${employeeBaseFragment.employeeBase}
    ${workAreaBaseFragment.workAreaBase}
  `
};
