import { computed, reactive } from "vue";
// Graphql
import useAuth from "@/api/auth/useAuth";
import { useQuery, useResult, useMutation } from "@vue/apollo-composable";
import {
  ScheduleQuery,
  SchedulesQuery,
  ScheduleEmployeeInsertInput,
  ScheduleWorkAreaInsertInput,
  ScheduleFullFragment,
  DemandTemplateInsertInput,
  DemandInsertInput
} from "@/graphql/types";
import { SCHEDULES } from "@/graphql/schedules/queries/schedules";
import { SCHEDULE } from "@/graphql/schedules/queries/schedule";
import { CREATE_SCHEDULE } from "@/graphql/schedules/mutations/createSchedule";
import { DELETE_SCHEDULE } from "@/graphql/schedules/mutations/deleteSchedule";
import { UPDATE_SCHEDULE } from "@/graphql/schedules/mutations/updateSchedule";
// shift
import { CREATE_SHIFT } from "@/graphql/shifts/mutations/createShift";
import { DELETE_SHIFT } from "@/graphql/shifts/mutations/deleteShift";
import { UPDATE_SHIFT } from "@/graphql/shifts/mutations/updateShift";
// demand
import { CREATE_DEMANDS } from "@/graphql/demand/mutations/createDemands";
// other
import produce from "immer";
import { checkSameDate } from "@/utils/dateHelpers";

/**
 ***************************
 * Local State
 ***************************
 */

export enum CalendarView {
  Day = "day",
  Week = "week",
  Month = "month"
}

export enum PlanningView {
  WorkAreas = "workAreas",
  Employees = "employees"
}

export interface ScheduleState {
  selectedSchedule: ScheduleFullFragment | null;
  calendarView: CalendarView;
  planningView: PlanningView;
  selectedDate: Date;
}

// Initialize time intercals state
const state: ScheduleState = reactive({
  selectedSchedule: null,
  calendarView: CalendarView.Day,
  planningView: PlanningView.WorkAreas,
  selectedDate: new Date()
});

/**
 ***************************
 * Store hook
 ***************************
 */

export default function useScheduleStore(options?: {
  scheduleId?: string;
  allSchedules?: boolean;
  querySelectedSchedule?: boolean;
}) {
  const { tenantId } = useAuth();

  /**
   * Query all schedules
   */

  const schedulesQueryVariables = computed(() => {
    return { tenantId: tenantId.value };
  });

  const { result: schedulesResult, loading: schedulesLoading, error: schedulesError } = useQuery<SchedulesQuery>(
    SCHEDULES,
    schedulesQueryVariables.value,
    () => ({
      enabled: options?.allSchedules === true
    })
  );
  const schedules = useResult(schedulesResult, [], data => data.schedule);

  /**
   * Query single schedule
   * Either by id or by selected schedule
   */

  // query variables
  const scheduleQueryVariables = computed(() => {
    // set schedule id
    const scheduleId = options?.querySelectedSchedule === true ? state.selectedSchedule?.id : options?.scheduleId;

    return { tenantId: tenantId.value, scheduleId: scheduleId };
  });

  // get schedule
  const {
    result: scheduleResult,
    loading: scheduleLoading,
    error: scheduleError,
    onResult: onScheduleResult
  } = useQuery<ScheduleQuery>(
    SCHEDULE,
    // dynamically generated variables if schedule id changes
    computed(() => scheduleQueryVariables.value),
    () => ({
      enabled: scheduleQueryVariables.value.scheduleId != undefined
    })
  );
  const schedule = useResult(scheduleResult, null, data => data.schedule[0]);

  /**
   * Create schedule
   */

  const {
    mutate: createSchedule,
    loading: createScheduleLoading,
    error: createScheduleError,
    onDone: onCreateScheduleSuccess,
    onError: onCreateScheduleFailed
  } = useMutation(CREATE_SCHEDULE, {
    update: (cache, { data: { insert_schedule_one } }) => {
      // read schedules data from cache
      // DON'T FORGET VARIABLES
      const schedulesData = cache.readQuery<SchedulesQuery>({
        query: SCHEDULES,
        variables: schedulesQueryVariables.value
      });

      // use new schedule as selected schedule
      state.selectedSchedule = insert_schedule_one;
      // add schedule to users list
      const schedulesUpdate = produce(schedulesData?.schedule, draftState => {
        // add schedule to state
        draftState?.push(insert_schedule_one);
      });

      // write data back to cache
      // DON'T FORGET VARIABLES
      cache.writeQuery({
        query: SCHEDULES,
        variables: schedulesQueryVariables.value,
        data: { schedule: schedulesUpdate }
      });
    }
  });

  // execute mutation
  const onCreateSchedule = (
    name: string,
    startDate: Date,
    endDate: Date,
    employees: ScheduleEmployeeInsertInput[],
    workAreas: ScheduleWorkAreaInsertInput[],
    demandTemplates: DemandTemplateInsertInput[]
  ) => {
    if (startDate === null || endDate === null) console.error("Start and end date must not be null");

    createSchedule({
      name: name,
      startDate: startDate,
      endDate: endDate,
      employees: employees,
      workAreas: workAreas,
      demandTemplates: demandTemplates,
      tenantId: tenantId.value
    });
  };

  onCreateScheduleSuccess(() => console.info("Schedule created"));
  onCreateScheduleFailed(() => console.info("Schedule creation failed", createScheduleError));

  /**
   * Delete schedule
   */

  const {
    mutate: deleteSchedule,
    loading: deleteScheduleLoading,
    error: deleteScheduleError,
    onDone: onDeleteScheduleSuccess,
    onError: onDeleteScheduleFailed
  } = useMutation(DELETE_SCHEDULE, {
    update: (cache, { data: { delete_schedule } }) => {
      // read schedules data from cache
      // DON'T FORGET VARIABLES
      const schedulesData = cache.readQuery<SchedulesQuery>({
        query: SCHEDULES,
        variables: schedulesQueryVariables.value
      });

      // get id
      const scheduleId = delete_schedule.returning.length > 0 ? delete_schedule.returning[0].id : null;

      // remove schedule to users list
      const schedulesUpdate = produce(schedulesData?.schedule, draft => {
        // add schedule to state
        return draft?.filter(schedule => schedule.id !== scheduleId);
      });

      // write data back to cache
      // DON'T FORGET VARIABLES
      cache.writeQuery({
        query: SCHEDULES,
        variables: schedulesQueryVariables.value,
        data: { schedule: schedulesUpdate }
      });
    }
  });

  const onDeleteSchedule = (scheduleId: string) => deleteSchedule({ tenantId: tenantId.value, scheduleId: scheduleId });

  onDeleteScheduleSuccess(() => console.info("Schedule deleted"));
  onDeleteScheduleFailed(() => console.info("Schedule delete failed", deleteScheduleError));

  /**
   * Update schedule
   */

  const {
    mutate: updateSchedule,
    loading: updateScheduleLoading,
    error: updateScheduleError,
    onDone: onUpdateScheduleSuccess,
    onError: onUpdateScheduleFailed
  } = useMutation(UPDATE_SCHEDULE);

  const onUpdateSchedule = (name: string, abbreviation: string, color: string) => {
    updateSchedule({
      name: name,
      abbreviation: abbreviation,
      color: color,
      scheduleId: options?.scheduleId,
      tenantId: tenantId.value
    });
  };

  onUpdateScheduleSuccess(() => console.info("Schedule updated"));
  onUpdateScheduleFailed(() => console.info("Schedule update failed", updateScheduleError));

  /**
   ***************************** Shifts *****************************
   */

  /**
   * Create shift
   */

  const {
    mutate: createShift,
    loading: createShiftLoading,
    error: createShiftError,
    onDone: onCreateShiftSuccess,
    onError: onCreateShiftFailed
  } = useMutation(CREATE_SHIFT, {
    update: (cache, { data: { insert_shift_one } }) => {
      // read shifts data from cache
      // DON'T FORGET VARIABLES
      const scheduleData = cache.readQuery<ScheduleQuery>({
        query: SCHEDULE,
        variables: scheduleQueryVariables.value
      });
      // only update cache if query is already stored
      if (scheduleData) {
        // update data if query is already in cache
        // add shift to shift list of schedule
        const scheduleUpdate = produce(scheduleData.schedule, draftState => {
          // add shift to state
          draftState[0].shifts.push(insert_shift_one);
        });

        // write data back to cache
        // DON'T FORGET VARIABLES
        cache.writeQuery({
          query: SCHEDULE,
          variables: scheduleQueryVariables.value,
          data: { schedule: scheduleUpdate }
        });
      }
    }
  });

  const onCreateShift = (startDateTime: Date, endDateTime: Date, employeeId: string, workAreaId: string) => {
    createShift({
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      employeeId: employeeId,
      workAreaId: workAreaId,
      scheduleId: state.selectedSchedule?.id,
      tenantId: tenantId.value
    });
  };

  onCreateShiftSuccess(() => console.info("Shift created"));
  onCreateShiftFailed(() => console.info("Shift creation failed", createShiftError));

  /**
   * Delete shift
   */

  const {
    mutate: deleteShift,
    loading: deleteShiftLoading,
    error: deleteShiftError,
    onDone: onDeleteShiftSuccess,
    onError: onDeleteShiftFailed
  } = useMutation(DELETE_SHIFT, {
    update: (cache, { data: { delete_shift } }) => {
      // read shifts data from cache
      // DON'T FORGET VARIABLES
      const scheduleData = cache.readQuery<ScheduleQuery>({
        query: SCHEDULE,
        variables: scheduleQueryVariables.value
      });

      const deletedId = delete_shift.returning[0].id;

      if (scheduleData) {
        // remove shift from schedule shift list
        const scheduleUpdate = produce(scheduleData.schedule, draft => {
          // remove shift from state
          const index = draft[0].shifts.findIndex(shift => shift.id === deletedId);
          if (index !== -1) draft[0].shifts.splice(index, 1);
        });

        // write data back to cache
        // DON'T FORGET VARIABLES
        cache.writeQuery({
          query: SCHEDULE,
          variables: scheduleQueryVariables.value,
          data: { schedule: scheduleUpdate }
        });
      }
    }
  });

  const onDeleteShift = (shiftId: string) => deleteShift({ tenantId: tenantId.value, shiftId: shiftId });

  onDeleteShiftSuccess(() => console.info("Shift deleted"));
  onDeleteShiftFailed(() => console.info("Shift delete failed", deleteShiftError));

  /**
   * Update schedule
   */

  const {
    mutate: updateShift,
    loading: updateShiftLoading,
    error: updateShiftError,
    onDone: onUpdateShiftSuccess,
    onError: onUpdateShiftFailed
  } = useMutation(UPDATE_SHIFT);

  const onUpdateShift = (startDateTime: Date, endDateTime: Date, shiftId: string) => {
    updateShift({
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      shiftId: shiftId,
      tenantId: tenantId.value
    });
  };

  onUpdateShiftSuccess(() => console.info("Shift updated"));
  onUpdateShiftFailed(() => console.info("Shift update failed", updateShiftError));

  /**
   ***************************** Demand *****************************
   */

  /**
   * Create multiple demand objects
   */

  const {
    mutate: createDemands,
    loading: createDemandsLoading,
    error: createDemandsError,
    onDone: onCreateDemandsSuccess,
    onError: onCreateDemandsFailed
  } = useMutation(CREATE_DEMANDS, {
    update: (cache, { data: { insert_demand } }) => {
      // read shifts data from cache
      // DON'T FORGET VARIABLES
      const scheduleData = cache.readQuery<ScheduleQuery>({
        query: SCHEDULE,
        variables: scheduleQueryVariables.value
      });
      // only update cache if query is already stored
      if (scheduleData) {
        // update data if query is already in cache
        // add shift to shift list of schedule
        const scheduleUpdate = produce(scheduleData.schedule, draftState => {
          // add shift to state
          draftState[0].demands.push(...insert_demand.returning);
        });

        // write data back to cache
        // DON'T FORGET VARIABLES
        cache.writeQuery({
          query: SCHEDULE,
          variables: scheduleQueryVariables.value,
          data: { schedule: scheduleUpdate }
        });
      }
    }
  });

  // execute mutation
  const onCreateDemands = (demandList: DemandInsertInput[]) => {
    createDemands({ objects: demandList });
  };

  onCreateDemandsSuccess(() => console.info("Demands created"));
  onCreateDemandsFailed(() => console.info("Demands creation failed", createDemandsError));

  /**
   ***************************** Status *****************************
   */

  const loading = computed(() => {
    if (schedulesLoading.value === true) return true;
    else if (scheduleLoading.value === true) return true;
    else if (createScheduleLoading.value === true) return true;
    else if (deleteScheduleLoading.value === true) return true;
    else if (updateScheduleLoading.value === true) return true;
    // shifts
    else if (createShiftLoading.value === true) return true;
    else if (deleteShiftLoading.value === true) return true;
    else if (updateShiftLoading.value === true) return true;
    // demand
    else if (createDemandsLoading.value === true) return true;
    else return false;
  });

  const error = computed(() => {
    if (schedulesError.value) return schedulesError.value;
    else if (scheduleError.value) return scheduleError.value;
    else if (createScheduleError.value) return createScheduleError.value;
    else if (deleteScheduleError.value) return deleteScheduleError.value;
    else if (updateScheduleError.value) return updateScheduleError.value;
    // shifts
    else if (createShiftError.value) return createShiftError.value;
    else if (deleteShiftError.value) return deleteShiftError.value;
    else if (updateShiftError.value) return updateShiftError.value;
    // demand
    else if (createDemandsError.value) return createDemandsError.value;
    else return null;
  });

  /**
   ***************************** Local State *****************************
   */

  // set selected schedule
  const setSelectedSchedule = (schedule: ScheduleFullFragment | null) => {
    state.selectedSchedule = schedule;
  };

  // set calendar view and update shifts
  const setCalendarView = (view: CalendarView) => {
    state.calendarView = view;
  };
  // set planning view
  const setPlanningView = (view: PlanningView) => {
    state.planningView = view;
  };
  // set date and update shifts
  const setDate = (date: Date) => {
    state.selectedDate = date;
  };

  // shifts based on date and calendar view
  const shifts = computed(() => {
    if (state.selectedSchedule) {
      return state.selectedSchedule.shifts.filter(
        shift => checkSameDate(shift.start_datetime, state.selectedDate, state.calendarView) === true
      );
    }
    return [];
  });

  // demands based on date and calendar view
  const demands = computed(() => {
    if (state.selectedSchedule?.demands) {
      return state.selectedSchedule.demands.filter(
        demand => checkSameDate(demand.start_datetime, state.selectedDate, state.calendarView) === true
      );
    }
    return [];
  });

  return {
    tenantId,
    // status
    loading,
    error,
    // queries
    schedules,
    schedule,
    onScheduleResult,
    // create
    onCreateSchedule,
    onCreateScheduleSuccess,
    onCreateScheduleFailed,
    // delete
    onDeleteSchedule,
    onDeleteScheduleSuccess,
    // update
    onUpdateSchedule,
    onUpdateScheduleSuccess,
    // local state
    setSelectedSchedule,
    setCalendarView,
    setPlanningView,
    setDate,
    shifts,
    demands,
    employees: computed(() => (state.selectedSchedule ? state.selectedSchedule.employees : [])),
    workAreas: computed(() => (state.selectedSchedule ? state.selectedSchedule.work_areas : [])),
    calendarView: computed(() => state.calendarView),
    planningView: computed(() => state.planningView),
    selectedDate: computed(() => state.selectedDate),
    selectedSchedule: computed(() => state.selectedSchedule),
    // shifts
    onCreateShift,
    onCreateShiftSuccess,
    onDeleteShift,
    onDeleteShiftSuccess,
    onUpdateShift,
    onUpdateShiftSuccess,
    // demand
    onCreateDemands,
    onCreateDemandsSuccess
  };
}
