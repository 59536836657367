import gql from "graphql-tag";

export const shiftBaseFragment = {
  shiftBase: gql`
    fragment ShiftBase on shift {
      id
      start_datetime
      end_datetime
      schedule_id
      work_area_id
      employee_id
    }
  `
};
