import gql from "graphql-tag";

// delete employee
export const DELETE_SCHEDULE = gql`
  mutation deleteSchedule($scheduleId: uuid!, $tenantId: uuid!) {
    delete_schedule(where: { id: { _eq: $scheduleId }, tenant_id: { _eq: $tenantId } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
