import gql from "graphql-tag";

import { scheduleBaseFragment } from "@/graphql/schedules/fragments/scheduleBase";

// get all users with role employee
export const SCHEDULES = gql`
  query schedules($tenantId: uuid!) {
    schedule(where: { tenant_id: { _eq: $tenantId } }) {
      ...ScheduleBase
    }
  }
  ${scheduleBaseFragment.scheduleBase}
`;
