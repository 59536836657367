import gql from "graphql-tag";

import { shiftFullFragment } from "@/graphql/shifts/fragments/shiftFull";

// update employee
export const UPDATE_SHIFT = gql`
  mutation updateShift($shiftId: uuid!, $tenantId: uuid!, $startDateTime: timestamptz!, $endDateTime: timestamptz!) {
    update_shift(
      where: { id: { _eq: $shiftId }, tenant_id: { _eq: $tenantId } }
      _set: { start_datetime: $startDateTime, end_datetime: $endDateTime }
    ) {
      returning {
        ...ShiftFull
      }
    }
  }
  ${shiftFullFragment.shiftFull}
`;
