import gql from "graphql-tag";

export const scheduleBaseFragment = {
  scheduleBase: gql`
    fragment ScheduleBase on schedule {
      id
      name
      start_date
      end_date
      tenant_id
    }
  `
};
