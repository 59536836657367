import gql from "graphql-tag";

import { scheduleBaseFragment } from "@/graphql/schedules/fragments/scheduleBase";
import { shiftFullFragment } from "@/graphql/shifts/fragments/shiftFull";
import { demandBaseFragment } from "@/graphql/demand/fragments/demandBase";

export const scheduleFullFragment = {
  scheduleFull: gql`
    fragment ScheduleFull on schedule {
      ...ScheduleBase
      work_areas {
        id
        name
        color
        abbreviation
      }
      employees {
        id
        first_name
        last_name
        abbreviation
        color
      }
      shifts {
        ...ShiftFull
      }
      demands {
        ...DemandBase
      }
    }
    ${scheduleBaseFragment.scheduleBase}
    ${shiftFullFragment.shiftFull}
    ${demandBaseFragment.demandBase}
  `
};
