import gql from "graphql-tag";

import { scheduleFullFragment } from "@/graphql/schedules/fragments/scheduleFull";

// create new schedule with contract
export const CREATE_SCHEDULE = gql`
  mutation createSchedule(
    $name: String = ""
    $tenantId: uuid!
    $startDate: date!
    $endDate: date!
    $workAreas: [schedule_work_area_insert_input!]!
    $employees: [schedule_employee_insert_input!]!
    $demandTemplates: [demand_template_insert_input!]!
  ) {
    insert_schedule_one(
      object: {
        name: $name
        start_date: $startDate
        end_date: $endDate
        tenant_id: $tenantId
        schedule_work_areas: { data: $workAreas }
        schedule_employees: { data: $employees }
        demand_templates: { data: $demandTemplates }
      }
    ) {
      ...ScheduleFull
    }
  }

  ${scheduleFullFragment.scheduleFull}
`;
