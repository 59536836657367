import gql from "graphql-tag";

import { scheduleBaseFragment } from "@/graphql/schedules/fragments/scheduleBase";

// update employee
export const UPDATE_SCHEDULE = gql`
  mutation updateSchedule($scheduleId: uuid!, $tenantId: uuid!, $name: String!, $startDate: date!, $endDate: date!) {
    update_schedule(
      where: { id: { _eq: $scheduleId }, tenant_id: { _eq: $tenantId } }
      _set: { name: $name, start_date: $startDate, end_date: $endDate }
    ) {
      returning {
        ...ScheduleBase
      }
    }
  }
  ${scheduleBaseFragment.scheduleBase}
`;
