import gql from "graphql-tag";

export const demandBaseFragment = {
  demandBase: gql`
    fragment DemandBase on demand {
      id
      amount
      start_datetime
      end_datetime
      work_area_id
      demand_parent_id
      schedule_id
    }
  `
};
