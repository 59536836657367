import gql from "graphql-tag";

import { scheduleFullFragment } from "@/graphql/schedules/fragments/scheduleFull";

// get employee by id
export const SCHEDULE = gql`
  query schedule($scheduleId: uuid!, $tenantId: uuid!) {
    schedule(where: { id: { _eq: $scheduleId }, tenant_id: { _eq: $tenantId } }) {
      ...ScheduleFull
    }
  }
  ${scheduleFullFragment.scheduleFull}
`;
