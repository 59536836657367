import gql from "graphql-tag";

import { demandBaseFragment } from "../fragments/demandBase";

// create new employee with contract
export const CREATE_DEMANDS = gql`
  mutation createDemands($objects: [demand_insert_input!]!) {
    insert_demand(objects: $objects) {
      affected_rows
      returning {
        ...DemandBase
      }
    }
  }
  ${demandBaseFragment.demandBase}
`;
