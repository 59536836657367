import gql from "graphql-tag";

// delete employee
export const DELETE_SHIFT = gql`
  mutation deleteShift($shiftId: uuid!, $tenantId: uuid!) {
    delete_shift(where: { id: { _eq: $shiftId }, tenant_id: { _eq: $tenantId } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
