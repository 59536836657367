import gql from "graphql-tag";

import { shiftFullFragment } from "@/graphql/shifts/fragments/shiftFull";

// create new shift with contract
export const CREATE_SHIFT = gql`
  mutation createShift(
    $tenantId: uuid!
    $startDateTime: timestamptz!
    $endDateTime: timestamptz!
    $scheduleId: uuid!
    $employeeId: uuid!
    $workAreaId: uuid!
  ) {
    insert_shift_one(
      object: {
        start_datetime: $startDateTime
        end_datetime: $endDateTime
        tenant_id: $tenantId
        schedule_id: $scheduleId
        employee_id: $employeeId
        work_area_id: $workAreaId
      }
    ) {
      ...ShiftFull
    }
  }

  ${shiftFullFragment.shiftFull}
`;
